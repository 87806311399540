<template>
    <deliverList :status="4" />
</template>

<script>
import deliverList from './list.vue';
export default {
    components: {
        deliverList
    }
}
</script>

<style></style>
